import React from 'react'
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/elma8zon.module.css'
import { useSelector } from 'react-redux'

const StockThree = () => {
  const {stockIndicators} = useSelector(state => state.cart)

  return (
    <>
<section className={`${styles.home}`}>
       <Sidebar active="ma8zoon" />  
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}> مؤشرات المخزون </h2>
          {
            stockIndicators?
""
:
<h1 className={`${styles.h1} `}>
            غير مفعلة في هذا الحساب
          </h1>
          }
          
          </div>
          </section>
    </>
  )
}

export default StockThree