import React from 'react'
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import SubCahser from './SubCasher';



const SubBranch = ({data}) => {
    const columns = useMemo(
        () => [
          {
            accessorKey: 'casherId', //access nested data with dot notation
            header: ' كود الخزينة',
            size: 150,
          },
          {
            accessorKey: 'casherName',
            header: 'اسم الخزينة ',
            size: 150,
            
          },
          {
            accessorKey: 'casherValue',
            header: 'قيمة الخزينة ',
            size: 150,
            enableSorting: true,
          },
        ],
        [],
      );
    
      const table = useMaterialReactTable({
        columns,
        data,
        columnResizeDirection: 'rtl',
        enableExpandAll: false,
        muiDetailPanelProps: () => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        }),
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
          size: 'small',
          variant: 'outlined',
        },
        muiPaginationProps: {
          color: 'secondary',
          shape: 'rounded',
          variant: 'outlined',
          rowsPerPageOptions: [10, 20, 50], // Options for rows per page
  
         
        },
        pagination: {
          pageSize: 20, // Default page size
        },
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        renderDetailPanel: ({ row }) => (
          <section>
            <SubCahser data={row.original.casherDetails} />
          </section>
        ),
        pagination: {
          pageSize: 20// Default page size
        },
      })
      return <MaterialReactTable table={table} />;
    };

export default SubBranch