import React from 'react'
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';



const SubSizeTable = ({data}) => {
    const columns = useMemo(
        () => [
          {
            accessorKey: 'sizeCode', //access nested data with dot notation
            header: ' كود المقاس',
            size: 150,
          },
          {
            accessorKey: 'sizeName', //access nested data with dot notation
            header: ' اسم المقاس',
            size: 150,
          },
          {
            accessorKey: 'totalQty',
            header: 'الكمية ',
            size: 150,
            enableSorting: true,
          },
          {
            accessorKey: 'totalValue', 
            header: 'القيمة',
            size: 200,
          },
        ],
        [],
      );
    
      const table = useMaterialReactTable({
        columns,
        data,
        initialState: {
          sorting: [{ id: 'qty', desc: true }], // Sort by 'lastName' ascending by default
        },
         //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      });
    
      return (
     
      <MaterialReactTable table={table} />
      
    )
    };

export default SubSizeTable