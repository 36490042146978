import React, { useState, useEffect } from "react";
import styles from "../../Styles/elma8zon.module.css";
import Sidebar from "../../Layout/Sidebar";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from "../../config";
import TableStokeTwo from "./TableStokeTwo";
import Form from "react-bootstrap/Form";
import { driver } from "driver.js";
import { format } from "date-fns";
import up from "../../assets/images/upward-arrow.png";
const StokeTwo = () => {
  /*   const { token } = useSelector((state) => state.user);
  const[InventoryData,setInventoryData]=useState()
  const[data,setData]=useState([])
  const[load,setLoad]=useState(true)
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("lastYear");
  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]); // Depend on selectedOption to update dates when it changes

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  useEffect(() => {
    if(dateFrom&& dateTo){
    axios
      .get(`${Environment.baseURL}/api/Inventory?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setInventoryData(response.data)
        setLoad(false)
     
      })
      .catch((err) => {
        console.log(err.response);
      });

      axios
      .get(`${Environment.baseURL}/api/Inventory/getInventoryDataByBranches?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data)
        setLoad(false)
     
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)
    }
  }, [selectedOption,dateFrom , dateTo]);
   */
  const { token } = useSelector((state) => state.user);
  const [InventoryData, setInventoryData] = useState([]);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("lastYear");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate()
        );
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const timeout = setTimeout(() => {
      if (dateFrom && dateTo) {
        axios
          .get(
            `${Environment.baseURL}/api/${Environment.project}/InventoryGBCSDashboard?from=${dateFrom}&to=${dateTo}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setInventoryData(response.data);
          })
          .catch((err) => {
            console.log(err.response);
          });

        axios
          .get(
            `${Environment.baseURL}/api/${Environment.project}/InventoryGBCSDashboard/getInventoryDataByBranches?from=${dateFrom}&to=${dateTo}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setData(response.data);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err.response);
          });
        setLoad(true);
      }
    }, 500); // Adjust the delay as needed

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [dateFrom, dateTo]);

  /*   const fetchInventoryData = async () => {
    setLoad(true);
    try {
      const inventoryResponse = await axios.get(
        `${Environment.baseURL}/api/Inventory?from=${dateFrom}&to=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInventoryData(inventoryResponse);
      
      const branchesResponse = await axios.get(
        `${Environment.baseURL}/api/Inventory/getInventoryDataByBranches?from=${dateFrom}&to=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(branchesResponse);
    } catch (err) {
      console.error('Error fetching data:', err.response);
    } finally {
      setLoad(false);
    }
  }; */
  const driverObj = driver({
    showProgress: false,
    nextBtnText: "التالي",
    prevBtnText: "السابق",
    doneBtnText: "انهاء",
    steps: [
      {
        element: "#itr",
        popover: {
          title:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;">معدل دوران المخزون (ITR):</p>',
          description:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;">يقيس عدد المرات التي يتم فيها بيع وتجديد المخزون خلال فترة زمنية معينة.</p> <p style="color: red; margin-bottom:0; font-family:Cairo-Medium;">•	الأهمية</p> <span style=" font-family:Cairo-Medium; ">: يساعد في معرفة مدى كفاءة الشركة في إدارة المخزون. ارتفاع معدل الدوران يعني أن المخزون يُباع بسرعة، بينما الانخفاض قد يشير إلى وجود فائض غير مرغوب فيه.</span>',
        },
      },
      {
        element: "#dsi",
        popover: {
          title:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;"> مدة دورة المخزون (DSI):</p>',
          description:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;">هي عدد الأيام التي تستغرقها الشركة لبيع المخزون المتاح.</p> <p style="color: red; margin-bottom:0;font-family:Cairo-Medium;">•	الأهمية</p> <span style=" font-family:Cairo-Medium; ">: كلما كانت مدة الدورة أقل، دل ذلك على كفاءة في إدارة المخزون وتحويله إلى مبيعات. مدة دورة أطول قد تعني تراكم المخزون وانخفاض المبيعات.</span>',
          side: "bottom",
        },
      },
      {
        element: "#str",
        popover: {
          title:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;"> نسبة بيع المخزون (STR):</p> ',
          description:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;">هذا المؤشر يقيس نسبة المخزون الذي تم بيعه خلال فترة معينة مقارنة بما تم استلامه.</p> <p style="color: red; margin-bottom:0;font-family:Cairo-Medium;">•	الأهمية</p> <span style=" font-family:Cairo-Medium; ">: تساعد على فهم مدى قوة الطلب على المنتجات وكفاءة البيع. نسبة بيع مرتفعة تشير إلى مبيعات قوية، بينما نسبة منخفضة قد تعني وجود مشكلة في تسويق المنتجات. </span>',
          side: "left",
        },
      },
      {
        element: "#isr",
        popover: {
          title:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;">نسبة المخزون إلى المبيعات (ISR):</p>',
          description:
            '<p style=" margin-bottom:0; font-family:Cairo-Medium;">تقارن كمية المخزون بقيمة المبيعات. </p> <p style="color: red; margin-bottom:0;font-family:Cairo-Medium;">•	الأهمية</p> <span style=" font-family:Cairo-Medium; ">: تساعد في معرفة ما إذا كانت الشركة تحتفظ بمخزون أكثر من اللازم مقارنة بمبيعاتها . بناءً على هذه النسبة، يمكن مقارنة أداء الشركة في إدارة المخزون.</span> <p style="color: red; margin-bottom:0;font-family:Cairo-Medium;">•	إذا كانت النسبة مرتفعة</p> <span style=" font-family:Cairo-Medium; ">: يعني أن الشركة تحتفظ بمخزون أكبر مما تحتاجه مقارنة بحجم مبيعاتها. قد يشير هذا إلى وجود فائض في المخزون، مما يؤدي إلى زيادة التكاليف المرتبطة بالتخزين أو إمكانية تقادم المنتجات.</span><p style="color: red; margin-bottom:0;font-family:Cairo-Medium;">•	إذا كانت النسبة منخفضة</p> <span style=" font-family:Cairo-Medium; ">: يعني أن الشركة تبيع المخزون بسرعة أو أنها تحتفظ بمخزون قليل نسبيًا مقارنة بمبيعاتها. هذا قد يكون مؤشرًا جيدًا على الكفاءة، ولكن إذا كانت النسبة منخفضة جدًا، فقد تواجه الشركة مشكلة في تلبية الطلبات المستقبلية، مما يؤدي إلى نفاد المخزون وخسارة فرص البيع.</span> <p style=" font-family:Cairo-Medium; ">•	تكون نسبة المخزون إلى المبيعات المثالية بين 0.5 إلى 1، مما يعني أن الشركة تحتفظ بمخزون كافٍ لتغطية نصف إلى سنة كاملة من المبيعات</p>',
          side: "bottom",
        },
      },
      // More magical steps...
    ],
  });
  function startTheMagicShow() {
    driverObj.drive();
  }
  const { stockMovement } = useSelector((state) => state.cart);

  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="ma8zoon" />
        <div className={`${styles.homeContainer}`}>
          {
            stockMovement?
            <>
             <div className={`${styles.div__share}`}>
            <h2 className={`${styles.h2} m-4`}> حركة المخزون </h2>
            <div className={`${styles.btnshare} m-4`}>
              <Button onClick={startTheMagicShow}>
                {" "}
                ماذا تعني هذة المؤشرات ؟{" "}
              </Button>
              <img alt="" src={up} />
            </div>
          </div>
         
            {!load ? (
              <div className={`${styles.block}`}>
                <Row className={`${styles.dash}`}>
                  <Col>
                    <Row className="mt-5 mb-4" dir="rtl">
                      <Col>
                        <label>اختر التاريخ</label>
                        <Form.Select
                          aria-label="Default select example"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="currentDay">اليوم الحالي</option>
                          <option value="lastMonth">اخر شهر</option>
                          <option value="lastQuarter">اخر ثلاثة اشهر</option>
                          <option value="lastYear">اخر سنة</option>
                          <option value="custom">اختر تاريخ محدد</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        {selectedOption === "custom" && (
                          <div className={`${styles.col__date}`}>
                            <div>
                              <label>من تاريخ </label>
                              <Form.Control
                                type="date"
                                value={dateFrom}
                                onChange={handleDateFromChange}
                              />
                            </div>
                            <div>
                              <label>الي تاريخ</label>
                              <Form.Control
                                type="date"
                                value={dateTo}
                                onChange={handleDateToChange}
                              />
                            </div>
                          </div>
                        )}

                        {selectedOption !== "custom" && (
                          <div className={`${styles.col__date}`}>
                            <div>
                              <label>من تاريخ </label>
                              <Form.Control
                                type="date"
                                value={dateFrom}
                                readOnly
                              />
                            </div>
                            <div>
                              <label>الي تاريخ</label>
                              <Form.Control
                                type="date"
                                value={dateTo}
                                readOnly
                              />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className={`${styles.activities}`} dir="rtl">
                      <div
                        id="itr"
                        className={`${styles.activities__body} ${styles.transit}`}
                      >
                        <p> معدل دوران الاصناف </p>
                        <p>( ITR )</p>
                        <h2>{InventoryData?.inventoryTurnover}</h2>
                      </div>
                      <div className={`${styles.activities__body}`} id="dsi">
                        <p> عدد ايام بيع المخزون </p>
                        <p>( DSI )</p>
                        <h2>{InventoryData?.dsi}</h2>
                      </div>
                      <div className={`${styles.activities__body}`} id="str">
                        <p> معدل البيع</p>
                        <p>( STR )</p>
                        <h2>{InventoryData?.sellThroughRate} %</h2>
                      </div>
                      <div className={`${styles.activities__body}`} id="isr">
                        <p> معدل تحويل المخزون لمبيعات </p>
                        <p> ( ISR )</p>
                        <h2>{InventoryData?.inventoryToSalesRatio}</h2>
                      </div>
                    </div>

                    <div className="mt-5">
                      <TableStokeTwo data={data} />
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div class="loader">
                <div class="justify-content-center jimu-primary-loading"></div>
              </div>
            )}
      
            </>
            :
            <h1 className={`${styles.h1} `}>
            غير مفعلة في هذا الحساب
          </h1>
          }
         
        </div>
      </section>
    </>
  );
};

export default StokeTwo;
