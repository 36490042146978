import { useMemo,useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

const ThirdTable = ({data}) => {
  
    const columns = useMemo(
        () => [
            {
              accessorKey: 'vendorCode',
              header: 'كود المورد',
            },
            {
              accessorKey: 'vendorName',
              header: '  اسم المورد ',
     
              
            },
            {
              accessorKey: 'totalMoney',
              header: 'إجمالي القيمة ',
              columnDefType: "display",
              enableColumnOrdering: 1,
              Cell: ({ renderedCellValue, row, cell }) => (
                <>
      
                  <div>
                          {row.original.totalMoney?
      <p>{row.original.totalMoney.toFixed(2)}</p>
      :
      ''
              }
                          </div>
                </>
              ),
            },
            {
                accessorKey: 'totalQty',
                header: ' إجمالي الكمية',
              },
             
          ],
          [],
      );
    
      
      const table = useMaterialReactTable({
        columns,
        data,
        columnResizeDirection: 'rtl',
        enableExpandAll: false,
        muiDetailPanelProps: () => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        }),
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
          size: 'small',
          variant: 'outlined',
        },
        muiPaginationProps: {
          color: 'secondary',
          shape: 'rounded',
          variant: 'outlined',
          rowsPerPageOptions: [10, 20, 50], // Options for rows per page
  
         
        },
        pagination: {
          pageSize: 20, // Default page size
        },
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        
        pagination: {
          pageSize: 20// Default page size
        },
      })
      return <MaterialReactTable table={table} />;
}

export default ThirdTable