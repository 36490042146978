import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import Environment from '../../../config';
export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (userCredentials) => {
        const request = await axios.post(`${Environment.baseURL}/api/${Environment.project}/AuthGBCSDashboard/login?userCode=${userCredentials.userCode}&password=${userCredentials.userPassword}`, userCredentials);
        const response = await request.data;
        return response;
    }
);
const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        usr: null,
        error: null,
        token: localStorage.getItem('token') || '',
        userName: localStorage.getItem('userName'),
    },
    reducers: {
        login(state, action) {
            state.usr = action.payload
            state.token = action.payload.token
            state.userName = action.payload.username;
            localStorage.setItem('userName', action.payload.username);
            localStorage.setItem('token', action.payload.token);

        },
        logout(state) {
            state.usr = null;
            state.token = null;
            localStorage.clear();
            localStorage.removeItem('token');

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.usr = action.payload;
                state.token = action.payload.token;
                state.error = null;
                state.userName = action.payload.name;
                localStorage.setItem('user', JSON.stringify(action.payload));
                localStorage.setItem('userName', action.payload.username);
                localStorage.setItem('token', action.payload.token);

            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.usr = null;
                console.log(action.error.message)
                if (action.error.message === 'Request failed with status code 401') {
                    state.error = 'Access Denied! Invalid Credentials';
                } else {
                    state.error = action.error.message
                }
            })

    }
})
export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
export const selectUserName = (state) => state.user.usr?.userName;