import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "../Styles/Sidebar.module.css";
import { useDispatch } from "react-redux";
import { logout } from "../Component/Redux/slices/UserSlice";
import logo from '../logo.png'
import Environment from "../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { Accordion } from "@mantine/core";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Sidebar = (props) => {
  const { token, usr ,userName} = useSelector((state) => state.user);
  const [active, setActive] = useState(`${props.active}`);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };
  const [show, setShow] = useState(true);
  const [showres, setShowres] = useState(false);

  const handleClose = () => setShowres(false);
  const handleShow = () => setShowres(true);

  return (
    <>
      <div>
       <div className={`${styles.top}`}></div> 
        <div className={`${styles.center}`}>
          <Offcanvas
            show={show}
            onClick={() => setShow(true)}
            placement="end"
            name="end"
            className={`${styles.sidebar}`}
            scroll={true}
            backdrop={true}
          >
            <Offcanvas.Header className="text-align-center justify-content-center">
              <Offcanvas.Title className="text-align-center">
                <img alt='' src={logo} className={`${styles.logo}`}/>
                 </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={`${styles.username}`}>
              <ul>
                <Accordion chevronPosition="left" defaultValue={active}>
                  <Accordion.Item
                    value="dashboard"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                      نقاط البيع
                    </Accordion.Control>

                    <Accordion.Panel className={`${styles.acc__body}`}>
                      <NavLink to="/">
                        <Accordion.Panel>نظرة عامة</Accordion.Panel>
                      </NavLink>
                      <NavLink to="/sale">
                        <Accordion.Panel>النقدية</Accordion.Panel>
                      </NavLink>
                      <NavLink to="/saletwo">
                        <Accordion.Panel>المبيعات</Accordion.Panel>
                      </NavLink>
                      <NavLink to="/internet">
                      <Accordion.Panel>مبيعات الأنترنت</Accordion.Panel>
</NavLink>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item
                    value="ma8zoon"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                      المخزون
                    </Accordion.Control>
                    <NavLink to="/stock">
                      <Accordion.Panel className={`${styles.acc__body}`}>
                        احصائيات المخزون
                      </Accordion.Panel>
                    </NavLink>
                    <NavLink to="/stock2">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      حركة المخزون
                    </Accordion.Panel>
                    </NavLink>
                    <NavLink to="/stock3">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      مؤشرات المخزون
                    </Accordion.Panel>
                    </NavLink>
                  </Accordion.Item>

                  <Accordion.Item
                    value="sho2on"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                    شئون العاملين
                    </Accordion.Control>
                    <NavLink to="/employees">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      العاملين
                    </Accordion.Panel>
                  </NavLink>
                  <NavLink to="/employeesChart">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      احصائيات العاملين
                    </Accordion.Panel>
                  </NavLink>
                  <NavLink to="/mortbat">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      المرتبات
                    </Accordion.Panel>
                    </NavLink>
                  </Accordion.Item>
                  <Accordion.Item
                    value="MALYA"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                  المالية
                    </Accordion.Control>
                    <NavLink to="/money">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      نظرة عامة
                    </Accordion.Panel>
                  </NavLink>
                  </Accordion.Item>
                </Accordion>

                <li className={` ${styles.li}`}>
                  <span className={`${styles.span}`} onClick={handleLogout}>
                    تسجيل خروج
                  </span>
                </li>
              </ul>
              <div dir="rtl">
              <p className="username">{localStorage.getItem('userName')}</p>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        {[ 'sm'].map((expand) => (
        <Navbar key={expand} expand={expand} className=" mb-3 navres">
          <Container fluid>
           
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowres((prevState) => !prevState)}/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
            
                <Offcanvas.Header className="text-align-center justify-content-center" closeButton>
              <Offcanvas.Title className="text-align-center" id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img alt='' src={logo} className={`${styles.logo}`}/>
                 </Offcanvas.Title>
         
              </Offcanvas.Header>
              <Offcanvas.Body className={`${styles.username}`}>
              <ul>
                <Accordion chevronPosition="left" defaultValue={active}>
                  <Accordion.Item
                    value="dashboard"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                      نقاط البيع
                    </Accordion.Control>

                    <Accordion.Panel className={`${styles.acc__body}`}>
                      <NavLink to="/">
                        <Accordion.Panel>نظرة عامة</Accordion.Panel>
                      </NavLink>
                      <NavLink to="/sale">
                        <Accordion.Panel>النقدية</Accordion.Panel>
                      </NavLink>
                      <NavLink to="/saletwo">
                        <Accordion.Panel>المبيعات</Accordion.Panel>
                      </NavLink>
                      <NavLink to="/internet">
                      <Accordion.Panel>مبيعات الأنترنت</Accordion.Panel>
</NavLink>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item
                    value="ma8zoon"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                      المخزون
                    </Accordion.Control>
                    <NavLink to="/stock">
                      <Accordion.Panel className={`${styles.acc__body}`}>
                        احصائيات المخزون
                      </Accordion.Panel>
                    </NavLink>
                    <NavLink to="/stock2">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      حركة المخزون
                    </Accordion.Panel>
                    </NavLink>
                    <NavLink to="/stock3">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      مؤشرات المخزون
                    </Accordion.Panel>
                    </NavLink>
                  </Accordion.Item>

                  <Accordion.Item
                    value="sho2on"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                    شئون العاملين
                    </Accordion.Control>
                    <NavLink to="/employees">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      العاملين
                    </Accordion.Panel>
                  </NavLink>
                  <NavLink to="/employeesChart">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      احصائيات العاملين
                    </Accordion.Panel>
                  </NavLink>
                  <NavLink to="/mortbat">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      المرتبات
                    </Accordion.Panel>
                    </NavLink>
                  </Accordion.Item>
                  <Accordion.Item
                    value="MALYA"
                    className={`${styles.acc__body}`}
                  >
                    <Accordion.Control className={`${styles.acc__control}`}>
                  المالية
                    </Accordion.Control>
                    <NavLink to="/money">
                    <Accordion.Panel className={`${styles.acc__body}`}>
                      نظرة عامة
                    </Accordion.Panel>
                  </NavLink>
                  </Accordion.Item>
                </Accordion>

                <li className={` ${styles.li}`}>
                  <span className={`${styles.span}`} onClick={handleLogout}>
                    تسجيل خروج
                  </span>
                </li>
              </ul>
              <div dir="rtl">
              <p className="username">{localStorage.getItem('userName')}</p>
              </div>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      </div>
    </>
  );
};

export default Sidebar;
