import React, { useEffect, useState } from "react";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/dashboard.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from "../../config";
import ReactApexChart from "react-apexcharts";
import TableUser from "./TableUser";
import TableSale from "./TableSale";
import Form from "react-bootstrap/Form";
import { driver } from "driver.js";

import { format } from "date-fns";
const Dashboard = () => {
  const {posoverView} = useSelector(state => state.cart)

  const [totalInvoices, settotalInvoices] = useState();
  const [data, setData] = useState([]);
  const [dataSale, setDataSale] = useState([]);
  const [totalMoney, settotalMoney] = useState();
  const [totalQty, settotalQty] = useState();
  const [averageMoney, setaverageMoney] = useState();
  const [averageQty, setaverageQty] = useState();
  const { token } = useSelector((state) => state.user);
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return Math.floor(val); // Convert to whole number
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.floor(val);
          },
        },
      },
    },
  });
  const [load, setLoad] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("currentDay");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate()
        );
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]); // Depend on selectedOption to update dates when it changes

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  /*   useEffect(() => {
    if(dateFrom&& dateTo){
    axios
      .get(`${Environment.baseURL}/api/ChasherCharts/getCasherNetByBranch?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const branchNames = response.data.map((item) => item.branchName);
        const totalInvoices = response.data.map((item) => item.totalInvoices);
        const totalMoney = response.data.map((item) => item.totalMoney);
        const totalQty = response.data.map((item) => item.totalQty);
        setState({
          series: [
           { name: "Total Invoices", data: totalInvoices }, 
            { name: "Total Money", data: totalMoney },
             { name: "Total Quantity  ", data: totalQty },
          ],
          options: {
            ...state.options,
            xaxis: {
              categories: branchNames,
            },
          },
        });
        console.log(response.data,'blabla')
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)
    }

  }, [selectedOption,dateFrom , dateTo]); */

  /*   useEffect(() => {
    if(dateFrom&& dateTo){
    axios
      .get(`${Environment.baseURL}/api/ChasherCharts?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        settotalInvoices(response.data.totalInvoices);
        settotalMoney(response.data.totalMoney);
        settotalQty(response.data.totalQty);
        setaverageMoney(response.data.averageMoney);
        setaverageQty(response.data.averageQty);
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });

      axios
      .get(`${Environment.baseURL}/api/ChasherCharts/getCasherNetByUser?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });

      axios
      .get(`${Environment.baseURL}/api/ChasherCharts/getCasherNetBySales?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDataSale(response.data);
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)
    }
  }, [dateFrom , dateTo]); */
  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const timeout = setTimeout(() => {
      if (dateFrom && dateTo) {
        axios
          .get(
            `${Environment.baseURL}/api/${Environment.project}/ChasherChartsGBCSDashboard/getCasherNetByBranch?from=${dateFrom}&to=${dateTo}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            const branchNames = response.data.map((item) => item.branchName);
            const totalInvoices = response.data.map(
              (item) => item.totalInvoices
            );
            const totalMoney = response.data.map((item) => item.totalMoney);
            const totalQty = response.data.map((item) => item.totalQty);
            setState({
              series: [
                /*       { name: "Total Invoices", data: totalInvoices }, */
                { name: "Total Money", data: totalMoney },
                /*             { name: "Total Quantity  ", data: totalQty }, */
              ],
              options: {
                ...state.options,
                xaxis: {
                  categories: branchNames,
                },
              },
            });
            console.log(response.data, "blabla");
            setLoad(false);
          })
          .catch((err) => {
            console.log(err.response);
          });
        axios
          .get(
            `${Environment.baseURL}/api/${Environment.project}/ChasherChartsGBCSDashboard?from=${dateFrom}&to=${dateTo}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            settotalInvoices(response.data.totalInvoices);
            settotalMoney(response.data.totalMoney);
            settotalQty(response.data.totalQty);
            setaverageMoney(response.data.averageMoney);
            setaverageQty(response.data.averageQty);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err.response);
          });

        axios
          .get(
            `${Environment.baseURL}/api/${Environment.project}/ChasherChartsGBCSDashboard/getCasherNetByUser?from=${dateFrom}&to=${dateTo}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setData(response.data);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err.response);
          });

        axios
          .get(
            `${Environment.baseURL}/api/${Environment.project}/ChasherChartsGBCSDashboard/getCasherNetBySales?from=${dateFrom}&to=${dateTo}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setDataSale(response.data);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err.response);
          });

        setLoad(true);
      }
    }, 500); // Adjust the delay as needed

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [dateFrom, dateTo]);
  /*   useEffect(() => {
    const driverObj = driver({
    // Define steps for the tour
    steps: [
      {
        element: '#step1',
        popover: {
          title: 'Step 1',
          description: 'This is the first step.',
          position: 'right',
        },
      },
      {
        element: '#step2',
        popover: {
          title: 'Step 2',
          description: 'This is the second step.',
          position: 'bottom',
        },
      },
      // Add more steps as needed
    ]
  })
    // Start the tour
    driverObj.driver();
  }, []); */

  const driverObj = driver({
    showProgress: false,
    nextBtnText: "التالي",
    prevBtnText: "السابق",
    doneBtnText: "انهاء",
    steps: [
      {
        element: "#element-of-mystery",
        popover: {
          title: "Abracadabra!",
          description: "Watch as I reveal the secrets of this element.",
        },
      },
      {
        element: "#enchanted-element",
        popover: {
          title: "Secrets Revealed",
          description: "This step will leave you spellbound.",
        },
      },
      // More magical steps...
    ],
  });
  function startTheMagicShow() {
    driverObj.drive();
  }
  return (
    <>
      <section className={`${styles.home}`}>
      <Sidebar active="dashboard" />
        <div className={`${styles.homeContainer}`}>
     
          <h2 className={`${styles.h2} m-4`} onClick={startTheMagicShow}>
            نظرة عامة{" "}
          </h2>
          {
            posoverView?
            (!load ? 
              <>
              <div className={`${styles.block}`}>
                <Row className={`${styles.dash}`}>
                  <Col>
                    <Row className="mt-5 mb-4" dir="rtl">
                      <Col id="element-of-mystery">
                        <label>اختر التاريخ</label>
                        <Form.Select
                          aria-label="Default select example"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="currentDay">اليوم الحالي</option>
                          <option value="lastMonth">اخر شهر</option>
                          <option value="lastQuarter">اخر ثلاثة اشهر</option>
                          <option value="lastYear">اخر سنة</option>
                          <option value="custom">اختر تاريخ محدد</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        {selectedOption === "custom" && (
                          <div
                            className={`${styles.col__date}`}
                            id="enchanted-element"
                          >
                            <div>
                              <label>من تاريخ </label>
                              <Form.Control
                                type="date"
                                value={dateFrom}
                                onChange={handleDateFromChange}
                              />
                            </div>
                            <div>
                              <label>الي تاريخ</label>
                              <Form.Control
                                type="date"
                                value={dateTo}
                                onChange={handleDateToChange}
                              />
                            </div>
                          </div>
                        )}
  
                        {selectedOption !== "custom" && (
                          <div
                            className={`${styles.col__date}`}
                            id="enchanted-element"
                          >
                            <div>
                              <label>من تاريخ </label>
                              <Form.Control
                                type="date"
                                value={dateFrom}
                                readOnly
                              />
                            </div>
                            <div>
                              <label>الي تاريخ</label>
                              <Form.Control type="date" value={dateTo} readOnly />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className={`${styles.activities}`} dir="rtl">
                      <div
                        className={`${styles.activities__body} ${styles.transit}`}
                      >
                        <p>عدد الفواتير </p>
                        <h2>{totalInvoices}</h2>
                      </div>
                      <div className={`${styles.activities__body}`}>
                        <p>إجمالي القيمة </p>
                        <h2>
                          {totalMoney !== undefined
                            ? totalMoney.toFixed(2)
                            : "Loading..."}
                        </h2>
                      </div>
                      <div className={`${styles.activities__body}`}>
                        <p> إجمالي الكمية</p>
                        <h2>{totalQty}</h2>
                      </div>
                      <div className={`${styles.activities__body}`}>
                        <p>متوسط القيمة للفاتورة </p>
                        <h2>
                          {averageMoney !== undefined
                            ? averageMoney.toFixed(2)
                            : "Loading..."}
                        </h2>
                      </div>
                      <div className={`${styles.activities__body}`}>
                        <p>متوسط الكمية للفاتورة </p>
                        <h2>
                          {averageQty !== undefined
                            ? averageQty.toFixed(2)
                            : "Loading..."}
                        </h2>
                      </div>
                    </div>
  
                    <div className="mt-5">
                      <div id="chart">
                        <ReactApexChart
                          options={state.options}
                          series={state.series}
                          type="bar"
                          height={350}
                        />
                      </div>
                      <div id="html-dist"></div>
                    </div>
                  </Col>
                </Row>
  
                <Row>
                  <Col className="mt-5" dir="rtl">
                    <TableUser data={data} />
                  </Col>
                  <Col className="mt-5" dir="rtl">
                    <TableSale data={dataSale} />
                  </Col>
                </Row>
              </div>
              </>
             : 
              <div class="loader">
                <div class="justify-content-center jimu-primary-loading"></div>
              </div>
            )
            :
            <h1 className={`${styles.h1} `}>
            غير مفعلة في هذا الحساب
          </h1>
          }
          
       
        </div>
      </section>
    </>
  );
};

export default Dashboard;
