import React from 'react'
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';



const SubCahser = ({data}) => {
    const columns = useMemo(
        () => [
          {
            accessorKey: 'docType', //access nested data with dot notation
            header: ' كود الحركة',
            size: 150,
          },
          {
            accessorKey: 'typeName',
            header: 'اسم الحركة ',
            size: 150,
            
          },
          {
            accessorKey: 'value',
            header: 'القيمة ',
            size: 150,
            enableSorting: true,
          },
        ],
        [],
      );
    
      const table = useMaterialReactTable({
        columns,
        data,
        columnResizeDirection: 'rtl',
        initialState: {
          sorting: [{ id: 'qty', desc: true }], // Sort by 'lastName' ascending by default
        },
         //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      });
    
      return (
        data.length>0?

      <MaterialReactTable table={table} />:
      <p>No sub rows available.</p>
    )
    };

export default SubCahser