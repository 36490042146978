import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import Environment from '../../../config';

export const UserOption = createAsyncThunk(
    'cart/UserOption',
    async (userCredentials) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${userCredentials.userToken}`, // Replace with your actual token or header value
                'Content-Type': 'application/json' // Add any other headers you need
            }
        };


        const request = await axios.get(`${Environment.baseURL}/api/${Environment.project}/OptionGBCSDashboard?userCode=${userCredentials.userCode}`,
            config);
        const response = await request.data;
        return response;
    }
);


const cartSlice = createSlice({
    name: 'cart',
    initialState : {
        loading: false,
        error: null,
        posoverView: '',
        poscash: '',
        possales: '',
        posonlineSales: '',
        stockStatistics: '',
        stockMovement: '',
        stockIndicators: '',
        hroverView: '',
        hremplyeeStatistics: '',
        payrollOverView:'',
        payrollStatistics: '',
        financeOverView: '',
    },
    reducers: {
        option: (state, action) => {
            const newItem = action.payload;
                
            state.posoverView= newItem.posoverView
            state.poscash= newItem.poscash
            state.possales= newItem.possales
                    state.posonlineSales= newItem.posonlineSales
                    state.stockStatistics= newItem.stockStatistics
                    state.stockMovement= newItem.stockMovement
                    state.stockIndicators= newItem.stockIndicators
                    state.hroverView= newItem.hroverView
                    state.hremplyeeStatistics= newItem.hremplyeeStatistics
                    state.payrollOverView= newItem.payrollOverView
                    state.payrollStatistics= newItem.payrollStatistics
                    state.financeOverView= newItem.financeOverView
                    
              
                
             }
             
         
        },extraReducers: (builder) => {
            builder
                .addCase(UserOption.pending, (state) => {
                    state.loading = true;
                })
                .addCase(UserOption.fulfilled, (state, action) => {
                    const newItem = action.payload;
                
                    state.posoverView= newItem.posoverView
                    state.poscash= newItem.poscash
                    state.possales= newItem.possales
                            state.posonlineSales= newItem.posonlineSales
                            state.stockStatistics= newItem.stockStatistics
                            state.stockMovement= newItem.stockMovement
                            state.stockIndicators= newItem.stockIndicators
                            state.hroverView= newItem.hroverView
                            state.hremplyeeStatistics= newItem.hremplyeeStatistics
                            state.payrollOverView= newItem.payrollOverView
                            state.payrollStatistics= newItem.payrollStatistics
                            state.financeOverView= newItem.financeOverView
                })
                .addCase(UserOption.rejected, (state, action) => {
                    state.loading = false;
                   
                    console.log(action.error.message)
                    if (action.error.message === 'Request failed with status code 401') {
                        state.error = 'Access Denied! Invalid Credentials';
                    } else {
                        state.error = action.error.message
                    }
                })
    
        }
      
    }


);
export const {cartActions,option} = cartSlice.actions
export default cartSlice.reducer