import { useMemo,useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';

import { Col, Row } from 'react-bootstrap';
import style from '../../Styles/dashboard.module.css'
import { ColorInput, Text } from '@mantine/core';
import SubTable from './SubTable';
import Environment from "../../config";
import useSWR, { mutate, useSWRConfig } from "swr";
import { useSelector } from "react-redux";
import axios from "axios";

const TableDash = ({data}) => {

  const columns = useMemo(
      () => [
          {
            accessorKey: 'itemCode',
            header: 'كود الصنف',
          },
          {
            accessorKey: 'itemName',
            header: 'اسم الصنف ',
          },
          {
            accessorKey: 'seasonsName',
            header: 'اسم الموسم ',
            size: 150,
            
          },
          {
              accessorKey: 'categoryName',
              header: ' التصنيف',
            },
            {
              accessorKey: 'totalQty',
              header: ' كمية المبيعات',
            },
            {
              accessorKey: 'totalValue',
              header: ' قيمة المبيعات ',
            },
        ],
        [],
    );
  
    
    const table = useMaterialReactTable({
      columns,
      data,
      columnResizeDirection: 'rtl',
      enableExpandAll: false,
      muiDetailPanelProps: () => ({
        sx: (theme) => ({
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(255,210,244,0.1)'
              : 'rgba(0,0,0,0.1)',
        }),
      }),
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      muiSearchTextFieldProps: {
        size: 'small',
        variant: 'outlined',
      },
      muiPaginationProps: {
        color: 'secondary',
        shape: 'rounded',
        variant: 'outlined',
        rowsPerPageOptions: [10, 20, 50], // Options for rows per page

       
      },
      pagination: {
        pageSize: 20, // Default page size
      },
      muiExpandButtonProps: ({ row, table }) => ({
        onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
        sx: {
          transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
          transition: 'transform 0.2s',
        },
      }),
      renderDetailPanel: ({ row }) => (
        <section>
          <SubTable data={row.original.details} />
        </section>
      ),
      pagination: {
        pageSize: 20// Default page size
      },
    })
    return <MaterialReactTable table={table} />;
  };
  export default TableDash