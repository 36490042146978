import React from 'react'
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import SubSizeTable from './SubSizeTable'

const SubTable = ({data}) => {
    const columns = useMemo(
        () => [
          {
            accessorKey: 'colorCode', //access nested data with dot notation
            header: ' كود اللون',
            size: 150,
          },
          {
            accessorKey: 'colorName', //access nested data with dot notation
            header: ' اسم اللون',
            size: 150,
          },
          {
            accessorKey: 'totalQty',
            header: 'الكمية ',
            size: 150,
            enableSorting: true,
          },
          {
            accessorKey: 'totalValue', 
            header: 'القيمة',
            size: 200,
          },
        ],
        [],
      );
    
      const table = useMaterialReactTable({
        columns,
        data,

        columnResizeDirection: 'rtl',
        initialState: {
          sorting: [{ id: 'qty', desc: true }], // Sort by 'lastName' ascending by default
        },
        enableExpandAll: false,
        muiDetailPanelProps: () => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        }),
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
          size: 'small',
          variant: 'outlined',
        },
        muiPaginationProps: {
          color: 'secondary',
          shape: 'rounded',
          variant: 'outlined',
          rowsPerPageOptions: [10, 20, 50], // Options for rows per page
  
         
        },
        pagination: {
          pageSize: 20, // Default page size
        },
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        renderDetailPanel: ({ row }) => (
          <section>
            <SubSizeTable data={row.original.sizes} />
          </section>
        ),
        pagination: {
          pageSize: 20// Default page size
        },
      })
      return (
        data.length>0?

      <MaterialReactTable table={table} />:
      <p>No sub rows available.</p>
    )
    };

export default SubTable