import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import style from '../../Styles/login.module.css'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Component/Redux/slices/UserSlice';
import axios from 'axios'
import Environment from '../../config';
import { option, UserOption } from '../Redux/slices/cartslice';
const Login = () => {
    const navigate = useNavigate()
    const [userCode, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const { loading, error } = useSelector((state) => state.user);
    const formRef = useRef(null);
    const dispatch = useDispatch();
    console.log(password)
    function login(e) {
        e.preventDefault();
       
        const reqData = {
           userCode,
           userPassword:encodeURIComponent(password),           
            }
        dispatch(loginUser(reqData)).then((result) => {
            console.log(result.payload,'ggg')
           
            if (result.payload) {
                const reqData2 = {
                    userCode,
                    userToken:result.payload.token,           
                     }
                dispatch(UserOption(reqData2)).then((result) => {
                    console.log(result.payload,'ggfhfgjng')
                })
            
                setCode('');
                setPassword('');
                navigate('/')
               
                localStorage.setItem('userCode', userCode)
            }
            else {

            }
        })
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(e);
        }
    };
    useEffect(() => {
        if (userCode) {
        axios.get(`${Environment.baseURL}/api/${Environment.project}/AuthGBCSDashboard/getUsername?userCode=${userCode}`, { 
        })
            .then((response) => {
                setName(response.data)
                console.log(response.data)

            }).catch((err) => { console.log(err.response) })
        }
    }, [userCode])
    return (
        <>
            <Container>
                <section className={`${style.loginpage}`}>
                    <Row >
                        <Col>
                            <h3 className={`${style.signin}`}>تسجيل الدخول </h3>
                            <div className={`${style.signin__form}`}>
                                <Form.Group className="mb-3 " controlId="email">
                                    <Form.Label className={`${style.label}`}>ادخل الكود</Form.Label>
                                    <Form.Control name="userCode" type='text' autoComplete="off" placeholder='' className={`${style.full}`} value={userCode} onChange={(e) => setCode(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3 mt-4 " controlId="password">
                                    <Form.Label className={`${style.label}`}>كلمة المرور</Form.Label>
                                    <Form.Control name="password"
                                        type='password'
                                        autoComplete="off"
                                        placeholder=''
                                        className={`${style.full}`}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        ref={formRef}
                                        onKeyPress={handleKeyPress} />
                                </Form.Group>
<p className={`${style.paraa}`}> مرحبا {name}</p>
                                <button className={style.log__btn} type='button' onClick={login}>تسجيل الدخول</button>             
                            </div>
                        </Col>
                    </Row>
                    <ToastContainer />
                </section>
            </Container>
        </>
    )
}

export default Login
