import React from 'react'
import Homee from '../Component/HomeTwo/Homee'

const HomeTwoo = () => {
  return (
    <>
<Homee/>
    </>
  )
}

export default HomeTwoo